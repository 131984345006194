import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'preact/hooks';
import { useRoute } from '../../hooks';
import { ActionType, useGlobalContext } from '../../hooks/use-global-context';
import { useTranslation } from 'preact-i18next';
import pick from 'lodash-es/pick';
import classNames from 'classnames';
import { LoginStep } from '../../Login';
import { logger } from '../../utils/log';
import Loading from '../Common/Loading/Loading';
import ManageAccountWallets from './ProfileWallets/ProfileWallets';
import Button from '../Common/Button/Button';
import { AccountInformation } from './AccountInformation/AccountInformation';
import { PersonalInformation } from './PersonalInformation/PersonalInformation';
import useVerificationMethod, { VerificationErrors } from '@/scripts/hooks/use-verification-method';
import * as Selectors from '@/scripts/utils/Selectors';
import UnauthorizedWebOrigins from '../Common/UnauthorizedWebOrigins/UnauthorizedWebOrigins';
import useUserApi from '@/scripts/hooks/use-user-api';
import { useValidators } from '@/scripts/hooks/use-validators';
import ProfileSidebar from '@/scripts/Components/Profile/ProfileSidebar/ProfileSidebar';
import Preferences from './Preferences/Preferences';
import { ROWND_LINKS } from '@/scripts/utils/links';
import useBottomSheet from '@/scripts/hooks/use-bottom-sheet';
import { validEmail } from '@/scripts/utils';
import { useInstantUser } from '@/scripts/hooks/use-instant-user';

export enum ProfileStep {
  INIT = 'init',
  EXISTING_ACCOUNT = 'existing_account',
}

export enum ProfileTypes {
  Account = 'account',
  Personal = 'personal',
  Preferences = 'preferences',
}

export const profileTypesTranslation: Record<ProfileTypes, { title: string }> = {
  [ProfileTypes.Account]: {
    title: 'Account information',
  },
  [ProfileTypes.Personal]: {
    title: 'Personal information',
  },
  [ProfileTypes.Preferences]: {
    title: 'Preferences',
  },
};

interface ProcessAndValidateInputOpts {
  format_only?: boolean;
}
export const PROFILE_FORM_CLASS = 'rph-account-fields-form';
export const PROFILE_TITLE_CLASS = `rph-account-fields-form__title--`;
const signInGreetingFieldPriority = ['first_name', 'email', 'phone_number'];

export default function Profile() {
  // Use these fields to determine which field to use for the greeting
  // If none contain a value, fallback to some default greetings defined
  // in the code.
  const { t } = useTranslation();
  const { state, dispatch } = useGlobalContext();
  const { navTo, setPopupRoute } = useRoute();
  const {
    addVerificationMethod,
    resetError: verificationErrorReset,
    isSubmitting: isSubmittingVerification,
    VerificationScreen,
    error: verificationError,
    step: verificationStep,
  } = useVerificationMethod();
  const { setUser, processFieldErrors } = useUserApi();
  const { validateAndNormalizePhoneNumber } = useValidators();
  // eslint-disable-next-line no-undef
  const timerId = useRef<NodeJS.Timeout | undefined>();
  const formRef = useRef<HTMLFormElement | null>(null);
  const { isBottomSheetEnabled, context: bottomSheetContext } = useBottomSheet();
  const { instantUserAccessToken } = useInstantUser();

  const { user, app, is_loading_user_data: isLoadingUserData, config, nav } = state;
  const { schema } = app;

  const profileTitle = app.config?.hub?.custom_content?.profile_modal?.title || t('Your profile');
  const profile = useMemo(() => app.config?.hub?.profile, [app.config?.hub?.profile]);
  const signOutButtonEnabled = useMemo(
    () => app.config?.hub?.profile?.sign_out_button?.enabled !== false,
    [app.config?.hub?.profile?.sign_out_button?.enabled],
  );

  const isMobileAppView = config?.displayContext === 'mobile_app';
  const viewport = window.visualViewport;

  const initialEditsTranslateY = Math.max(1000, viewport?.height || 0);

  const [edits, setEdits] = useState<Record<string, any>>({});
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [focused, setFocused] = useState<string | null>(null);
  const [verificationEdits, setVerificationEdits] = useState<Record<string, any>>({});
  const [saveEditsTranslateY, setSaveEditsTranslateY] = useState(initialEditsTranslateY);
  const appIcon = Selectors.appIcon(state);

  const [step, setStep] = useState(ProfileStep.INIT);
  const [isSaving, setIsSaving] = useState(false);
  const [mobileTab, setMobileTab] = useState<ProfileTypes>(ProfileTypes.Account);

  const editsHaveBeenMade = useMemo(
    () => Object.entries(edits).some(([key, value]) => user.data[key] !== value),
    [edits, user.data],
  );

  const shouldDisplaySaveEdits = useMemo(() => {
    return editsHaveBeenMade || isSaving || isSubmittingVerification;
  }, [isSaving, isSubmittingVerification, editsHaveBeenMade]);

  /**
   * handleSaveEditsContainerResize()
   *
   * Sets the TranslateY attribute value for the
   * .rph-account__mobile__save-edits container in order to place at the bottom of viewport.
   */
  const handleSaveEditsContainerResize = useCallback(() => {
    if (!viewport || !shouldDisplaySaveEdits || (!isMobileAppView && !isBottomSheetEnabled)) return;
    const offsetTop =
      (viewport?.height || 0) +
      (viewport?.offsetTop || 0) -
      (document.querySelector('.rph-account__mobile__save-edits')?.getBoundingClientRect()?.height || 0) -
      (document.querySelector('.rph-container-inner')?.getBoundingClientRect()?.top || 0);
    const scrollTop = document.querySelector('.rph-container-inner')?.scrollTop || 0;

    if (timerId.current) {
      clearTimeout(timerId.current);
    }
    timerId.current = setTimeout(() => {
      setSaveEditsTranslateY(offsetTop + scrollTop - window.scrollY);
    }, 25); // debounce time
  }, [isBottomSheetEnabled, isMobileAppView, viewport, shouldDisplaySaveEdits]);

  const activeMobileTab = useMemo(
    () => (isBottomSheetEnabled || isMobileAppView ? mobileTab : undefined),
    [isBottomSheetEnabled, isMobileAppView, mobileTab]
  );

  useEffect(() => {
    if (!viewport || (!isMobileAppView && !isBottomSheetEnabled)) return;
    const containerInnerElem = document.querySelector('.rph-container-inner');
    viewport.addEventListener('resize', handleSaveEditsContainerResize);
    viewport.addEventListener('scroll', handleSaveEditsContainerResize);
    containerInnerElem?.addEventListener('scroll', () => {
      window.scrollTo({ top: 0 });
      handleSaveEditsContainerResize();
    });
    handleSaveEditsContainerResize();
    return () => {
      viewport?.removeEventListener('resize', handleSaveEditsContainerResize);
      viewport?.removeEventListener('scroll', handleSaveEditsContainerResize);
      containerInnerElem?.removeEventListener('scroll', handleSaveEditsContainerResize);
    };
  }, [handleSaveEditsContainerResize, isBottomSheetEnabled, isMobileAppView, viewport, shouldDisplaySaveEdits]);

  const removeError = useCallback((fieldName: string) => {
    setErrors((prev) => {
      delete prev?.[fieldName];
      return prev;
    });
  }, []);

  const processAndValidateInput = useCallback(
    async (fieldName: string, value: any, opts: ProcessAndValidateInputOpts = {}) => {
      let newValue = value;
      let error: undefined | string;
      switch (fieldName) {
        case 'phone_number': {
          const normalized = await validateAndNormalizePhoneNumber(value);
          if (!normalized?.isValid) {
            if (!opts.format_only) {
              error = t('Invalid phone number');
            }
          }

          newValue = normalized.phoneNumber;
          break;
        }

        case 'email': {
          const isValid = validEmail(value);
          if (value && !isValid) {
            error = t('Invalid email');
          }
          break;
        }

        default: {
          if (schema?.[fieldName].type === 'object') {
            try {
              JSON.parse(value);
            } catch (err) {
              error = (err as Error).message;
            }
          }
          break;
        }
      }

      setEdits({
        ...edits,
        [fieldName]: newValue,
      });

      if (error) {
        setErrors((prev) => ({ ...prev, [fieldName]: error as string }));
      }
    },
    [edits, schema, t, validateAndNormalizePhoneNumber],
  );

  const onSignOut = () => {
    dispatch({ type: ActionType.SIGN_OUT });
    dispatch({ type: ActionType.SET_CONTAINER_VISIBLE, payload: { isVisible: false } });
  };

  const onReset = useCallback(() => {
    setStep(ProfileStep.INIT);
    setEdits({});
    setErrors({});
    setVerificationEdits({});
    verificationErrorReset();
    setSaveEditsTranslateY(initialEditsTranslateY);
  }, [initialEditsTranslateY, verificationErrorReset]);

  const handleInputChange = async (fieldName: string, value: any) => {
    verificationErrorReset();
    removeError(fieldName);
    processAndValidateInput(fieldName, value, { format_only: true });
  };

  const handleSelectChange = (fieldName: string, value: any) => {
    verificationErrorReset();
    removeError(fieldName);
    setEdits({
      ...edits,
      [fieldName]: app?.schema?.[fieldName]?.type === 'boolean' ? value === 'true' : value,
    });
  };

  const handleInputBlur = async (fieldName: string, value: any) => {
    removeError(fieldName);
    await processAndValidateInput(fieldName, value);
  };

  const handleEmailOrPhoneUpdate = (type: 'email' | 'phone', value: string) => {
    if (!state.use_modal && !isMobileAppView) {
      // Open the modal if the hub is open on the bottom left
      dispatch({ type: ActionType.SET_USE_MODAL, payload: true });
    }
    addVerificationMethod({
      userIdentifier: value,
      verificationType: type,
      redirectUrl: nav?.options?.post_verify_redirect || nav?.options?.post_login_redirect,
    });
  };

  const handleUniqueSchemaError = useCallback(() => {
    if (!schema) return;
    Object.keys(schema).forEach((fieldName) => {
      const field = schema[fieldName];
      if (field.unique) {
        setErrors((prev) => ({
          ...prev,
          [fieldName]: t(`This {{displayName}} already exists`, {
            displayName: (field.display_name || fieldName).toLowerCase(),
          }),
        }));
      }
    });
  }, [schema, t]);

  const verificationFields = useMemo(() => {
    let fields: string[] = [];
    // app verification fields
    if (app?.user_verification_fields) {
      fields = app?.user_verification_fields;
    } else if (app?.user_verification_field) {
      fields = [app?.user_verification_field];
    }
    return fields;
  }, [app?.user_verification_field, app?.user_verification_fields]);

  const handleFormSubmit = async (e: Event) => {
    if (!e || !e.target) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    const formData = new FormData(e.target as HTMLFormElement);
    const userData: Record<string, boolean | string | number> = {};
    formData.forEach((value, key) => {
      // Prevent saving email/phone to userData before verification
      if (verificationFields.includes(key)) return;

      const fieldType = app?.schema?.[key]?.type;
      let typedValue: any;
      switch (fieldType) {
        case 'boolean':
          typedValue = value === 'true';
          break;
        case 'number':
          typedValue = Number(value as string);
          break;
        case 'object':
          try {
            typedValue = JSON.parse(value as string);
          } catch (err) {
            typedValue = (value as string).split(',');
          }
          break;
        default:
          typedValue = value;
          break;
      }
      userData[key] = typedValue;
    });

    try {
      setIsSaving(true);

      await setUser({
        ...state.user.data,
        ...userData,
      });

      if (verificationFields.includes('email') && edits?.email) {
        setVerificationEdits({ email: edits.email });
        handleEmailOrPhoneUpdate('email', edits.email);
      } else if (verificationFields.includes('phone_number') && edits?.phone_number) {
        setVerificationEdits({ phone_number: edits.phone_number });
        handleEmailOrPhoneUpdate('phone', edits.phone_number);
      } else {
        setEdits({});
      }
    } catch (err: any) {
      logger.error('Error saving user data', err);
      const statusCode: number | undefined = err?.statusCode;

      if (err.payload?.field_errors) {
        setErrors(processFieldErrors(err.payload.field_errors));
      }

      if (statusCode === 409) {
        handleUniqueSchemaError();
      }
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    // Remove all edits if a verification error occurs
    // Set email error
    if (verificationError) {
      let errorMessage: string;
      switch (verificationError) {
        case VerificationErrors.EXISTING_ACCOUNT:
          errorMessage = VerificationErrors.EXISTING_ACCOUNT;
          break;
        case VerificationErrors.DEFAULT:
          errorMessage = `Verification failed. Please try again or contact ${
            state.app.config?.hub?.legal?.support_email || ROWND_LINKS.SUPPORT_EMAIL
          }`;
          break;
        case VerificationErrors.EXPIRED:
          errorMessage = 'Verification request expired.';
          break;
        default:
          break;
      }
      setErrors((prev) => ({
        ...prev,
        [edits?.email ? 'email' : 'phone_number']: errorMessage,
      }));
    }
  }, [edits?.email, state.app.config?.hub?.legal?.support_email, verificationEdits, verificationError]);

  useEffect(() => {
    if (verificationStep === LoginStep.SUCCESS) {
      setEdits({});
    }
  }, [verificationStep]);

  const signedInAsGreeting = useMemo(() => {
    let greeting = '';
    for (const field of signInGreetingFieldPriority) {
      if (user?.data?.[field]) {
        greeting = t(`You're signed in as {{signedInAs}}`, { signedInAs: user.data[field] });
        break;
      }
    }
    if (greeting?.includes('@privaterelay')) {
      greeting = t(`You're signed in with a private Apple ID`);
    } else if (instantUserAccessToken) {
      greeting = '';
      if (!state.use_modal) {
        greeting = 'Sign in to add more to your profile';
      }
    } else if (!greeting && user?.data?.anonymous_id) {
      greeting = t(`You're signed in as a guest`);
    } else if (!greeting) {
      greeting = t(`You're signed in`);
    }
    return greeting;
  }, [instantUserAccessToken, state.use_modal, t, user.data]);

  const appUserVisibleFields = useMemo(() => {
    let visibleFields: string[] = [];
    if (app.schema) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      visibleFields = Object.entries(app?.schema)
        .filter(([, field]) => field.user_visible)
        .map(([key]) => key);
    }
    return visibleFields;
  }, [app.schema]);

  // fields the user has that matches the list
  const userDataFields: Record<string, any> = useMemo(
    () => pick(user.data, appUserVisibleFields),
    [appUserVisibleFields, user.data]
  );

  // when the modal is shown then use all the fields
  const dataToShow: Record<string, any> = useMemo(() => {
    return nav.options?.visible_profile_fields
      ? pick(userDataFields, nav.options?.visible_profile_fields)
      : userDataFields;
  }, [nav.options?.visible_profile_fields, userDataFields]);

  useLayoutEffect(() => {
    if (!isMobileAppView) return;
    handleSaveEditsContainerResize();
  }, [editsHaveBeenMade, isSaving, isMobileAppView, handleSaveEditsContainerResize]);

  useEffect(() => {
    bottomSheetContext?.reset();
  }, [activeMobileTab, bottomSheetContext]);

  if (VerificationScreen) {
    return VerificationScreen;
  }

  if (step === ProfileStep.EXISTING_ACCOUNT) {
    return (
      <div className="rph-account rph-modal rph-account--existing">
        <h2 className="rph-account__title">Existing account</h2>
        <p className="rph-account__subtitle">
          The identifier you entered is associated with an existing account. If you own the existing account and would
          like to combine these accounts, please contact{' '}
          <a
            href={`mailto:${state.app.config?.hub?.legal?.support_email || ROWND_LINKS.SUPPORT_EMAIL}?subject=%5B${encodeURIComponent(
              app?.name || ''
            )}%3A%20${encodeURIComponent(
              app?.id || ''
            )}%5D%20Merge%20accounts%20request&body=Can%20you%20please%20describe%20the%20two%20accounts%20you%20would%20like%20to%20merge%3F%0A%0AAccount%201%3A%20${encodeURIComponent(
              user.data.email || user.data.phone_number || ''
            )}%0A%0AAccount%202%3A%0A%0ADescription%3A`}
          >
            {state.app.config?.hub?.legal?.support_email || ROWND_LINKS.SUPPORT_EMAIL}
          </a>{' '}
          for assistance.
        </p>
        <Button label="Back" handleOnClick={() => onReset()} />
      </div>
    );
  }

  if (state.app.invalid_web_origins) {
    return (
      <div className={classNames('rph-account rph-modal')}>
        {state.use_modal && app?.config?.hub?.auth?.show_app_icon && app?.icon && (
          <div className="rph-login__app-icon">
            <img src={appIcon} alt="Site logo" />
          </div>
        )}
        <h2 className="rph-account__title">{profileTitle}</h2>
        {((!state.use_modal && state.auth.access_token) || isMobileAppView) && (
          <p className="rph-account__subtitle">
            {signedInAsGreeting}{' '}
            {!isMobileAppView && signOutButtonEnabled && !instantUserAccessToken && (
              <button className="rph-button-link" onClick={onSignOut}>
                {t('Sign out')}
              </button>
            )}
          </p>
        )}
        <UnauthorizedWebOrigins />
        {state.use_modal && (
          <button className="rph-text-powered-by" onClick={() => setPopupRoute('/powered')}>
            {t('Powered by Rownd')}
          </button>
        )}
      </div>
    );
  }

  return (
    <div
      className={classNames('rph-account rph-modal', {
        'all-fields': state.use_modal,
        'all-fields-authenticated': state.auth.access_token,
        'rph-account--widget': !state.use_modal,
        [`rph-account--active-tab--${activeMobileTab}`]: activeMobileTab,
        'rph-account--active-tab--input-focused': focused,
      })}
    >
      <div style="display: none !important;">Don't display, adding this fixes preact rendering</div>
      {state.use_modal && app?.config?.hub?.auth?.show_app_icon && app?.icon && (
        <div className="rph-login__app-icon">
          <img src={appIcon} alt="Site logo" />
        </div>
      )}

      {activeMobileTab && activeMobileTab !== ProfileTypes.Account ? (
        <div className="rph-account__tab__title">
          <button
            type="button"
            onClick={() => {
              setMobileTab(ProfileTypes.Account);
              onReset();
            }}
          />
          {profileTypesTranslation[activeMobileTab].title}
        </div>
      ) : (
        <>
          <h2 className="rph-account__title">{profileTitle}</h2>
          {(state.auth.access_token || isMobileAppView) && (
            <p className="rph-account__subtitle">
              {signedInAsGreeting}{' '}
              {!isMobileAppView && !state.use_modal && !instantUserAccessToken && signOutButtonEnabled && (
                <button className="rph-button-link" onClick={onSignOut}>
                  {t('Sign out')}
                </button>
              )}
            </p>
          )}
        </>
      )}

      <div className="rph-account__content">
        {state.use_modal && !isMobileAppView && !isBottomSheetEnabled && <ProfileSidebar />}
        <form ref={formRef} className={PROFILE_FORM_CLASS} onSubmit={handleFormSubmit}>
          {Object.keys(state.user.data || {}).length === 0 && isLoadingUserData && state.auth.access_token && (
            <Loading />
          )}
          {shouldDisplaySaveEdits && (isMobileAppView || isBottomSheetEnabled) && (
            <div className="rph-account__mobile__save-edits" style={`transform:translateY(${saveEditsTranslateY}px)`}>
              <button type="button" className="rph-cancel-btn" onClick={() => onReset()}>
                {t('Cancel')}
              </button>
              <button type="submit">
                {isSaving || isSubmittingVerification ? <span className="rph-loading-circle" /> : t('Save edits')}
              </button>
            </div>
          )}
          {(!activeMobileTab || activeMobileTab === ProfileTypes.Account) && (
            <AccountInformation
              setStep={setStep}
              verificationFields={verificationFields}
              dataToShow={dataToShow}
              autoFocusField={nav.options?.auto_focus_field}
              errors={errors}
              handleInputChange={handleInputChange}
              handleSelectChange={handleSelectChange}
              handleInputBlur={handleInputBlur}
              edits={edits}
              handleEmailOrPhoneUpdate={handleEmailOrPhoneUpdate}
              isSubmittingVerification={isSubmittingVerification}
              setFocused={setFocused}
            />
          )}
          {(!activeMobileTab || activeMobileTab === ProfileTypes.Personal) && (
            <PersonalInformation
              verificationFields={verificationFields}
              dataToShow={dataToShow}
              autoFocusField={nav.options?.auto_focus_field}
              handleInputChange={handleInputChange}
              handleSelectChange={handleSelectChange}
              edits={edits}
              errors={errors}
              setFocused={setFocused}
            />
          )}
          {(!activeMobileTab || activeMobileTab === ProfileTypes.Preferences) && <Preferences />}

          {activeMobileTab === ProfileTypes.Account && (
            <div className="rph-account__content__mobile-tabs">
              {Object.values(ProfileTypes).map((item) => {
                let { title } = profileTypesTranslation[item];
                if (item === ProfileTypes.Account) return null;
                if (profile?.personal_information?.enabled === false && item === ProfileTypes.Personal) return null;
                if (profile?.preferences?.enabled === false && item === ProfileTypes.Preferences) return null;
                if (title === profileTypesTranslation.preferences.title && instantUserAccessToken) {
                  title = 'Support';
                }

                return (
                  <button
                    type="button"
                    onClick={() => {
                      setMobileTab(item);
                      onReset();
                    }}
                    className="rph-account__content__mobile-tabs__tab"
                    key={`mobile-button-type-${title}`}
                  >
                    {title}
                    <div className="rph-account__content__mobile-tabs__tab__arrow" />
                  </button>
                );
              })}
            </div>
          )}

          {!isLoadingUserData && user.loaded_once && <ManageAccountWallets state={state} />}

          {!state.use_modal &&
            state.auth.access_token &&
            (!instantUserAccessToken ? (
              <Button
                handleOnClick={() => navTo('/account/manage', 'account', { use_modal: true })}
                type="secondary"
                label={t('View full profile')}
              />
            ) : (
              <>
                <Button
                  handleOnClick={() => navTo('/account/login', 'account', { use_modal: true })}
                  type="primary"
                  label={t('Sign in or sign up')}
                />
                <Button
                  customClass="rph-account-fields-form__button-text"
                  handleOnClick={() => navTo('/account/manage', 'account', { use_modal: true })}
                  type="text"
                  label={t('View full profile')}
                />
              </>
            ))}

          {!state.auth.access_token && (
            <>
              <div style="min-height: fit-content !important">
                <Button
                  handleOnClick={() => navTo('/account/login', 'nav', { use_modal: true })}
                  label={t('Sign in or sign up')}
                />
              </div>
              <p style="margin: 0px !important">
                {t('When you verify your account, any information you share will be manageable here.')}
              </p>
            </>
          )}
        </form>
      </div>
      {(isBottomSheetEnabled || isMobileAppView) &&
        state.auth.access_token &&
        activeMobileTab === ProfileTypes.Account && (
          <div className="rph-account__footer__mobile">
            {signOutButtonEnabled && !instantUserAccessToken && (
              <Button label="Sign out" handleOnClick={onSignOut} type="tertiary" />
            )}
            <button className="rph-text-powered-by" onClick={() => setPopupRoute('/powered')}>
              {t('Powered by Rownd')}
            </button>
          </div>
        )}
      {state.use_modal && !isBottomSheetEnabled && !isMobileAppView && (
        <div className="rph-account__footer">
          <button className="rph-text-powered-by" onClick={() => setPopupRoute('/powered')}>
            {t('Powered by Rownd')}
          </button>
          {shouldDisplaySaveEdits ? (
            <div className="rph-account-save-changes">
              <Button
                customClass="rph-account-save-changes__button"
                handleOnClick={() => formRef?.current?.requestSubmit()}
                label={t('Save edits')}
                isLoading={isSaving || isSubmittingVerification}
              />
              <Button
                customClass="rph-account-save-changes__button"
                type="secondary"
                handleOnClick={() => onReset()}
                label={t('Cancel')}
                disabled={isSaving}
              />
              <p>{t('You have unsaved edits')}</p>
            </div>
          ) : state.auth.access_token && signOutButtonEnabled ? (
            <Button
              customClass="rph-account__footer__sign-out"
              type="tertiary"
              label={instantUserAccessToken ? t('Sign in') : t('Sign out')}
              handleOnClick={() => {
                if (instantUserAccessToken) {
                  navTo('/account/login', 'profile', {
                    use_modal: true,
                  });
                  return;
                }

                onSignOut();
              }}
            />
          ) : null}
        </div>
      )}
    </div>
  );
}
