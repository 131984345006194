import { isDate } from '../utils';
import * as Types from './types';
import { createLogger, logger } from '../utils/log';

const CLASS_NAME = 'rp-automation';

export const automationLogger = createLogger('automation');

export function computeLastRunId(automation: Types.Automation, trigger?: Types.Trigger): string {
  let type = ''; // default
  if (trigger && trigger.type === Types.TriggerType.TIME_ONCE) {
    type = Types.TriggerType.TIME_ONCE.toLowerCase();
  }
  return `automation_${automation.id}_last_run${type ? `_${type}` : ''}`;
}

export function computeLastRunTimestamp(
  automation: Types.Automation,
  trigger: Types.Trigger,
  meta?: Record<string, string>,
): Date | null | string {
  const lastRunId = computeLastRunId(automation, trigger);

  const metadata = meta?.[lastRunId];

  if (!metadata) {
    return null;
  }

  return isDate(metadata) ? new Date(metadata) : metadata;
}

export const automationClassName = (opts: { id: string; actionIdx: string | number; childIdx?: string | number }) =>
  `${CLASS_NAME}-${opts.id}-${opts.actionIdx}-${opts.childIdx !== undefined ? `-${opts.childIdx}` : ''}`;

export const convertStyleObjectToCss = (className: string, style?: Record<string, string>): string => {
  if (!style || Object.keys(style).length === 0) return '';
  return `.${className} {\n${Object.entries(style)
    .map(([k, v]) => `  ${k}: ${v}`)
    .join(';\n')};\n}`;
};

export const getDisplayedBackgroundColor = (element?: Element): string | undefined => {
  if (!element) return undefined;
  const transparentArray = ['transparent', 'rgba(0, 0, 0, 0)'];

  let backgroundColor = window.getComputedStyle(element).backgroundColor;
  while (transparentArray.includes(backgroundColor) && element.parentElement) {
    element = element.parentElement; // Move to the parent element
    backgroundColor = window.getComputedStyle(element).backgroundColor;
  }

  // return White if there is no background color set in the html page
  if (element === document.documentElement && transparentArray.includes(backgroundColor)) {
    return 'white';
  }
  return backgroundColor;
};

interface EventInfo {
  // The event name (like 'click')
  name: string;
  // The Document or Elements on which to add the event listeners
  // eslint-disable-next-line no-undef
  handlers: DocumentAndElementEventHandlers[];
  // The original selector from the action's trigger
  selector: string;
  // A list of elements found in with the selector
  elements: HTMLElement[];
}

// Returns an EventInfo object that contains all of the information necessary to setup event
// listeners for content types that require them.
export function determineEventInfo(trigger: Types.Trigger): EventInfo | null {
  switch (trigger.type.toUpperCase() as Types.TriggerType) {
    case Types.TriggerType.EVENT: {
      if (!trigger.target) {
        logger.error('An EVENT automation must have a target', trigger);
        return null;
      }
      const elements = findElements(trigger.target);
      // For this automation trigger, event listeners are added to each element found with the selector.
      return {
        name: trigger.value,
        handlers: elements,
        selector: trigger.target,
        elements,
      };
    }
    case Types.TriggerType.HTML_SELECTOR_VISIBLE:
      // For this trigger type, a 'scroll' event listener is added to the document object.
      return {
        name: 'scroll',
        selector: trigger.value,
        handlers: [document],
        elements: findElements(trigger.value),
      };
    default:
      return null;
  }
}

export function findElements(selector: string): HTMLElement[] {
  return Array.from(document.querySelectorAll<HTMLElement>(selector));
}

// Returns true if the element is visible to the user.
export function isVisible(elem: HTMLElement): boolean {
  if (!(elem instanceof Element)) {
    return false;
  }
  const style = getComputedStyle(elem);
  if (style.display === 'none') return false;
  if (style.visibility !== 'visible') return false;
  if (Number(style.opacity) < 0.1) return false;
  if (
    elem.offsetWidth + elem.offsetHeight + elem.getBoundingClientRect().height + elem.getBoundingClientRect().width ===
    0
  ) {
    return false;
  }
  const elemCenter = {
    x: elem.getBoundingClientRect().left + elem.offsetWidth / 2,
    y: elem.getBoundingClientRect().top + elem.offsetHeight / 2,
  };
  if (elemCenter.x < 0) return false;
  if (elemCenter.x > (document.documentElement.clientWidth || window.innerWidth)) return false;
  if (elemCenter.y < 0) return false;
  if (elemCenter.y > (document.documentElement.clientHeight || window.innerHeight)) return false;
  let pointContainer = document.elementFromPoint(elemCenter.x, elemCenter.y);
  if (!pointContainer) return false;
  do {
    if (pointContainer === elem) return true;
    // eslint-disable-next-line no-cond-assign
  } while (((pointContainer as unknown) = pointContainer.parentNode));
  return false;
}