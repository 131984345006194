import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { ActionType, SignInMethods, useGlobalContext } from './use-global-context';
import { LoginStep, LoginVerificationStatus } from '../Login';
import { useApi, useInterval } from '.';
import { logger } from '../utils/log';
import { useTranslation, Trans } from 'preact-i18next';
import { differenceInMinutes } from 'date-fns';
import preact, { h } from 'preact';
import classnames from 'classnames';
import Button from '../Components/Common/Button/Button';
import { FeatureFlagTypes, isFeatureEnabled } from '../utils/storage';
import * as Selectors from '../utils/Selectors';
import useUserApi from './use-user-api';
import { MessageType, sendMessageToApp } from '../utils/mobile-app';

type VerificationInitBody = {
  challenge_id: string;
  message: string;
  registration_status: string;
  init_data?: Record<string, any>;
};

interface AddVerificationMethodProps {
  verificationType: keyof SignInMethods | null;
  userIdentifier: any;
  redirectUrl?: string;
}

export enum VerificationErrors {
  EXISTING_ACCOUNT = 'existing_account',
  DEFAULT = 'default',
  EXPIRED = 'expired',
}

export default function useVerificationMethod() {
  const { state, dispatch } = useGlobalContext();
  const { config, user, app } = state;
  const { client: api } = useApi();
  const { retrieveUserInfo } = useUserApi();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState(LoginStep.INIT);
  const [error, setError] = useState<null | VerificationErrors>(null);
  const [requestId, setRequestId] = useState<string | null>(null);
  const [pollStart, setPollStart] = useState<number | null>(null);
  const [type, setType] = useState<'email' | 'phone'>('email');
  const [identifier, setIdentifier] = useState('');
  const [isResendingMessage, setIsResendingMessage] = useState(false);
  const [didResendMessage, setDidResendMessage] = useState(false);
  const [canResendMessage, setCanResendMessage] = useState(false);

  const hideVerificationIcons = !!app?.config?.hub?.customizations?.hide_verification_icons;
  const primaryColor = Selectors.primaryColor(state);

  const cancelVerificationMethod = () => {
    setStep(LoginStep.INIT);
    setIdentifier('');
    setIsResendingMessage(false);
    setDidResendMessage(false);
    setCanResendMessage(false);
  };

  useEffect(() => {
    if (error) {
      cancelVerificationMethod();
    }
  }, [error]);

  const addVerificationMethod = useCallback(
    async ({ verificationType, userIdentifier, redirectUrl }: AddVerificationMethodProps) => {
      setError(null);
      setStep(LoginStep.INIT);
      if ((step === LoginStep.WAITING && !isResendingMessage) || didResendMessage) {
        return;
      }

      // Construct the user_data object for the request payload.
      const userIdentifierField = verificationType === 'phone' ? 'phone' : 'email';
      const userData = user.data;

      const payload: Record<string, any> = {
        identifier: userIdentifier,
        user_data: Object.values(userData).some((f) => f !== null && f !== undefined) ? userData : {},
        app_id: state.app?.id,
        return_url: redirectUrl || state.config?.postLoginUrl || window.location.href,
      };

      // Submission
      try {
        setIsSubmitting(true);
        setType(userIdentifierField);
        setIdentifier(userIdentifier);
        setTimeout(() => setCanResendMessage(true), 10000);

        const resp: VerificationInitBody = await api
          .post('hub/verification/init', {
            headers: {
              Authorization: `Bearer ${state.auth.access_token}`,
              'x-rownd-app-key': config?.appKey,
            },
            json: payload,
          })
          .json();

        if (resp.message === 'Existing user found') {
          cancelVerificationMethod();
          setError(VerificationErrors.EXISTING_ACCOUNT);
          return;
        }

        if (resp.message === 'Method was updated') {
          cancelVerificationMethod();
          setStep(LoginStep.SUCCESS);
          setRequestId('');
          dispatch({
            type: ActionType.SET_REFRESH_USER_DATA,
            payload: { needs_refresh: true },
          });
          return;
        }

        setRequestId(resp.challenge_id);
        setStep(LoginStep.WAITING);
        setPollStart(Date.now());
      } catch (err: any) {
        logger.error(err);
        let errorResponse: { errorCode?: string; message?: string } = {};
        try {
          errorResponse = await err?.response?.json();
        } catch (e) {
          // No-op;
          cancelVerificationMethod();
        }

        if (err?.response?.status === 400) {
          if (errorResponse.message?.includes('Turnstile')) {
            err.errorCode = 'E_TVF';
          } else {
            setError(VerificationErrors.DEFAULT);
            cancelVerificationMethod();
            return;
          }
        }

        setError(VerificationErrors.DEFAULT);
      } finally {
        setIsSubmitting(false);
      }
    },
    [
      step,
      isResendingMessage,
      didResendMessage,
      user.data,
      state.app?.id,
      state.config?.postLoginUrl,
      state.auth.access_token,
      api,
      config?.appKey,
      dispatch,
    ],
  );

  const pollVerificationStatus = useCallback(async () => {
    try {
      const resp: any = await api
        .post(`hub/verification/challenge_status`, {
          headers: {
            Authorization: `Bearer ${state.auth.access_token}`,
            'x-rownd-app-key': config?.appKey,
          },
          json: {
            challenge_id: requestId,
            [type || 'email']: identifier,
          },
        })
        .json();

      let err: any;
      switch (resp.status) {
        case 'pending':
          err = new Error('Verification challenge is still pending');
          err.code = LoginVerificationStatus.PENDING;
          throw err;

        case 'expired':
          err = new Error('Verification challenge is still pending');
          err.code = LoginVerificationStatus.PENDING;
          throw err;

        case 'verified':
          break;

        default:
          err = new Error('Unknown login challenge status');
          throw err;
      }

      setStep(LoginStep.SUCCESS);
      setRequestId('');

      const userProfile = await retrieveUserInfo();
      if (userProfile?.data) {
        sendMessageToApp({
          type: MessageType.USER_DATA_UPDATE,
          payload: {
            data: userProfile.data,
            meta: state.user.meta,
          },
        });
      }
    } catch (err: any) {
      logger.log('login poll error', err);

      // If network error, try again up to 1 minute, else fail
      if (!err.code && differenceInMinutes(Date.now(), pollStart!) > 0) {
        setStep(LoginStep.ERROR);
        setError(VerificationErrors.DEFAULT);
        setIdentifier('');
        return;
      }

      // If request expires, then fail (assume > 6 mins is a failure/expiration)
      if ((err.status || err.code) && differenceInMinutes(Date.now(), pollStart!) > 6) {
        setStep(LoginStep.ERROR);
        setError(t('The sign in request expired.'));
        setIdentifier('');
        return;
      }

      if (err.status && err.status >= 400) {
        setStep(LoginStep.FAILURE);
        setError(VerificationErrors.DEFAULT);
        setIdentifier('');
        return;
      }
    }
  }, [
    api,
    config?.appKey,
    identifier,
    pollStart,
    requestId,
    retrieveUserInfo,
    state.auth.access_token,
    state.user.meta,
    t,
    type,
  ]);

  // Polling when a login flow is in progress
  useInterval(pollVerificationStatus, step === LoginStep.WAITING ? 5000 : null);

  useEffect(() => {
    if (isResendingMessage) {
      addVerificationMethod({
        userIdentifier: identifier,
        verificationType: type,
      });
      setDidResendMessage(true);
      setTimeout(() => {
        setIsResendingMessage(false);
        setTimeout(() => setDidResendMessage(false), 10000);
      }, 2500);
    }
  }, [isResendingMessage, addVerificationMethod, identifier, type]);

  const resendLinkClick = useCallback(() => {
    if (isResendingMessage || didResendMessage) return null;
    setIsResendingMessage(true);
  }, [didResendMessage, isResendingMessage]);

  const VerificationScreen = useMemo((): null | preact.JSX.Element => {
    if (step !== LoginStep.WAITING || error) {
      return null;
    }

    return (
      <div className="rph-login rph-modal">
        <h2 className="rph-login-waiting__title">
          {t('Verify your new {{identifier}}', {
            identifier: type === 'phone' ? t('phone number') : t('email'),
          })}
        </h2>
        <p className="rph-login-waiting__subtitle">
          <Trans
            t={t}
            i18nKey="Click the link in the message we just sent to <1>{{userIdentifier}}</1> to verify and finish."
            values={{ identifier }}
          >
            Click the link in the message we just sent to <strong>{{ identifier }}</strong> to verify and finish.&nbsp;
          </Trans>
        </p>
        {!hideVerificationIcons && (
          <div className="rph-login-waiting-image-container">
            <div
              className="rph-login-waiting-image-background"
              style={`background-color: ${state.computed_color_mode === 'dark' ? 'black' : primaryColor} !important`}
            />
            <div className={classnames('rph-login-waiting-image', { 'rph-login-phone': type === 'phone' })} />
          </div>
        )}
        <div className="rph-login-waiting-footer">
          <Button
            customClass="rph-login-waiting__button-resend"
            label={
              isResendingMessage
                ? t('Resending...')
                : didResendMessage
                ? t('Message was re-sent')
                : t('Re-send message')
            }
            hide={!canResendMessage}
            type="tertiary"
            handleOnClick={resendLinkClick}
          />
          {config?.displayContext === 'mobile_app' &&
            type === 'email' &&
            isFeatureEnabled({ storage: sessionStorage, feature: FeatureFlagTypes.OpenEmailInbox }) && (
              <a
                className="button rph-login-waiting__button-open-email"
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:"
              >
                {t('Open email app')}
              </a>
            )}
          <button
            className="rph-button-link rph-button-link-secondary rph-login-waiting-button"
            onClick={() => cancelVerificationMethod()}
          >
            {t('Cancel')}
          </button>
        </div>
      </div>
    );
  }, [
    canResendMessage,
    config?.displayContext,
    didResendMessage,
    error,
    hideVerificationIcons,
    identifier,
    isResendingMessage,
    primaryColor,
    resendLinkClick,
    state.computed_color_mode,
    step,
    t,
    type,
  ]);

  return {
    addVerificationMethod,
    step,
    isSubmitting,
    requestId,
    error,
    VerificationScreen,
    resetError: () => setError(null),
  };
}
