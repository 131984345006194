import { logger } from './utils/log';
import { MessageType, sendMessageToApp } from './utils/mobile-app';
import { FeatureFlagTypes, isFeatureEnabled } from './utils/storage';
import { sessionContainer } from './hooks/use-session-storage';

const bus = new EventTarget();

export enum EventType {
  AUTH = 'auth',
  SIGN_OUT = 'sign_out',
  USER_DATA = 'user_data',
  STORAGE_READY = 'storage_ready',
  USER_DATA_SAVED = 'user_data_saved',
  POST_AUTHENTICATION_API_REQUEST_COMPLETE = 'post_authentication_api_request_complete',
  POST_AUTHENTICATION_STEPS_ERROR = 'post_authentication_steps_error',
  ROOT_SYNC = 'root_sync',
  GROUP_INVITE_ACCEPT_COMPLETE = 'group_invite_accept_complete',
  AUTOMATION_POST_SIGN_IN_COMPLETE = 'automation_post_sign_in_complete',
  AUTOMATION_TRIGGERED = 'automation_triggered',
  AUTOMATION_COMPLETED = 'automation_completed',
  SIGN_IN_STARTED = 'sign_in_started',
  SIGN_IN_COMPLETED = 'sign_in_completed',
  SIGN_IN_FAILED = 'sign_in_failed',
  VERIFICATION_STARTED = 'verification_started',
  VERIFICATION_COMPLETED = 'verification_completed',
  PROMPT_TO_SIGN_IN_WITH_GOOGLE_INSTEAD = 'prompt_to_sign_in_with_google_instead',
}

export type TAuthEvent = {
  access_token: string;
  user_id: string;
  app_id: string;
};

export const events = {
  // eslint-disable-next-line no-undef
  addEventListener: (
    type: string,
    callback: EventListenerOrEventListenerObject | null,
    options?: boolean | AddEventListenerOptions | undefined,
  ): void => {
    if (!Object.values<string>(EventType).includes(type)) {
      console.warn(`Unknown event type: ${type}`);
      return;
    }

    bus.addEventListener(type, callback, options);
  },
  removeEventListener: bus.removeEventListener,
  dispatchEvent: (event: Event): boolean => {
    if (!Object.values<string>(EventType).includes(event.type)) {
      throw new Error(`Unknown event type: ${event.type}`);
    }

    return bus.dispatchEvent(event);
  },
  dispatch(type: EventType, data: any = {}): boolean {
    if (!Object.values<string>(EventType).includes(type)) {
      throw new Error(`Unknown event type: ${type}`);
    }

    const event = new CustomEvent(type, {
      detail: data,
    });

    logger.debug(`Dispatching event: ${type}:`, data);

    if (isFeatureEnabled({ storage: sessionContainer.storage, feature: FeatureFlagTypes.CanReceiveEventMessages })) {
      sendMessageToApp({
        type: MessageType.EVENT,
        payload: {
          event: type,
          data,
        },
      });
    }
    return bus.dispatchEvent(event);
  },
};

// Built-in event handlers
events.addEventListener(EventType.AUTH, () => {
  document.querySelectorAll('[data-rph-login-btn]').forEach((el) => {
    if (!(el instanceof HTMLElement)) {
      return;
    }

    el.dataset.rowndUnauthenticatedText = el.textContent!;
    el.textContent = el.dataset.rphLoggedInText || el.dataset.rowndAuthenticatedText || el.innerText;
  });
});

// LOG OUT
events.addEventListener(EventType.SIGN_OUT, () => {
  document.querySelectorAll('[data-rph-login-btn]').forEach((el) => {
    if (!(el instanceof HTMLElement)) {
      return;
    }

    el.textContent = el.dataset.rowndUnauthenticatedText || el.textContent;
  });
});
