import { ActionType, User, UserData, useGlobalContext } from './use-global-context';
import { logger } from '../utils/log';
import { events, EventType } from '../events';
import { useCallback } from 'preact/hooks';
import useApi from './use-api';
import { UserInfoResp } from '../DefaultContext';
import { MessageType, sendMessageToApp } from '../utils/mobile-app';
import useSafeLocation from './use-window-location';
import ky from 'ky';

export default function useUserApi() {
  const { dispatch, state } = useGlobalContext();
  const { client: api } = useApi();
  const safeLocation = useSafeLocation();

  const retrieveUserInfo = useCallback(async (): Promise<UserInfoResp | undefined> => {
    if (!state.auth.access_token || !state.app.id) {
      return;
    }

    try {
      dispatch({
        type: ActionType.SET_IS_LOADING_USER_DATA,
        payload: {
          loading: true,
        },
      });

      const userInfo = await api
        .get(`me/applications/${state.app.id}/data`, {
          authenticated: true,
        })
        .json<UserInfoResp>();
      dispatch({
        type: ActionType.LOAD_USER,
        payload: userInfo,
      });

      return userInfo;
    } finally {
      dispatch({
        type: ActionType.SET_IS_LOADING_USER_DATA,
        payload: {
          loading: false,
        },
      });
    }
  }, [api, dispatch, state.app.id, state.auth.access_token]);

  const callPostUserDataUpdateApi = useCallback(async (userData: UserData) => {
    const { method, extra_headers } = state.config?.postUserDataUpdateApi || {};
    const headers = {
      ...extra_headers,
      'Content-Type': 'application/json',
    };
    const body = {
      user_data: userData,
    };
    const options = {
      method,
      headers,
      body: JSON.stringify(body),
    };

    try {
      const response: { should_refresh_page: boolean; return_to: string } = await ky(
        state.config!.postUserDataUpdateApi!.url,
        options,
      ).json();
      logger.debug('postUserDataUpdateApi response', response);
      if (response.should_refresh_page) {
        return safeLocation.reload(); // refresh the page
      }
      if (response.return_to) {
        return safeLocation.replace(response.return_to);
      }
    } catch (err) {
      logger.error('postUserDataUpdateApi error', err);
    }
  }, [safeLocation, state.config]);

  const dispatchUserData = useCallback(
    (data: UserData): { data: UserData } => {
      dispatch({
        type: ActionType.SET_USER_DATA,
        payload: {
          data,
        },
      });
      events.dispatch(EventType.USER_DATA_SAVED, data);
      return { data };
    },
    [dispatch],
  );

  const saveUserData = useCallback(
    async (userData: UserData): Promise<{ data?: UserData; error?: Error }> => {
      dispatch({
        type: ActionType.SET_IS_SAVING_USER_DATA,
        payload: {
          saving: true,
        },
      });

      if (!state.auth.access_token) {
        return dispatchUserData(userData);
      }
      if (!state.app.id) {
        return { error: Error('Rownd: Missing app id') };
      }
      if (!userData) {
        return { error: Error('Rownd: Missing userData') };
      }

      try {
        const res: User = await api
          .put(`me/applications/${state.app.id}/data`, {
            authenticated: true,
            json: {
              data: userData,
            },
          })
          .json();

        if (state.config?.postUserDataUpdateApi) {
          callPostUserDataUpdateApi(userData);
        }

        sendMessageToApp({
          type: MessageType.USER_DATA_UPDATE,
          payload: {
            data: userData,
            meta: state.user.meta,
          },
        });

        return dispatchUserData(res.data);
      } catch (err) {
        // Get the latest user info from Rownd. Something was probably bad
        // with the data we just tried to save.
        // This is a bit hacky. We should be able to reset the state without
        // calling rownd again by rolling back to a previously good state...
        retrieveUserInfo();

        events.dispatch(EventType.USER_DATA_SAVED, { error: err });

        return { error: err as Error };
      } finally {
        dispatch({
          type: ActionType.SET_IS_SAVING_USER_DATA,
          payload: {
            saving: false,
          },
        });
      }
    },
    [
      api,
      callPostUserDataUpdateApi,
      dispatch,
      dispatchUserData,
      retrieveUserInfo,
      state.app.id,
      state.auth.access_token,
      state.config?.postUserDataUpdateApi,
      state.user.meta,
    ],
  );

  const saveUserField = useCallback(
    async (
      key: string,
      value: any,
    ): Promise<{
      data?: UserData | undefined;
      error?: Error | undefined;
    }> => {
      return saveUserData({ ...state.user.data, [`${key}`]: value });
    },
    [saveUserData, state.user.data],
  );

  const setUserValue = useCallback(
    async (key: string, value: any): Promise<UserData> => {
      const userData = await saveUserData({ ...state.user.data, [`${key}`]: value });

      if (userData.error instanceof Error) {
        throw userData.error;
      }

      if (!userData.data) {
        throw new Error(`Rownd userData was not saved { key: ${key}, value: ${value} }`);
      }

      return userData;
    },
    [saveUserData, state.user.data],
  );

  const setUser = useCallback(
    async (data: UserData): Promise<UserData> => {
      const userData = await saveUserData(data);

      if (userData.error instanceof Error) {
        throw userData.error;
      }

      if (!userData.data) {
        throw new Error('Rownd userData was not saved');
      }

      return userData;
    },
    [saveUserData],
  );

  const processFieldErrors = useCallback((errors: Record<string, string[]>): Record<string, string> => {
    return Object.entries(errors).reduce<Record<string, string>>((acc, [key, values]) => {
      acc[key] = values
        .map((value) => {
          return value[0].toUpperCase() + value.substring(1);
        })
        .join('. \n');

      return acc;
    }, {});
  }, []);

  return {
    setUserValue,
    setUser,
    saveUserData,
    saveUserField,
    retrieveUserInfo,
    callPostUserDataUpdateApi,
    processFieldErrors,
  };
}
