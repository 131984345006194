import { useCallback, useEffect, useState } from 'preact/hooks';
import { useGlobalContext, HubCustomizations, ActionType, GlobalState } from './hooks/use-global-context';

import { adjustColor, detectColorMode, determineTextColorForBgColor } from './utils';
import * as Selectors from './utils/Selectors';

const DEFAULT_PRIMARY_COLOR = '#5b13df';
const DEFAULT_PRIMARY_DARK_COLOR = '#c8aaff';

export default function Customizations() {
  const { state, dispatch } = useGlobalContext();

  const [hasLoaded, setHasLoaded] = useState(false);

  const handleColorModeChange = useCallback(
    (event: MediaQueryListEvent) => {
      let colorMode = '';
      if (event.matches) {
        // dark mode
        colorMode = 'dark';
      } else {
        // light mode
        colorMode = 'light';
      }

      dispatch({
        type: ActionType.SET_COLOR_SCHEME,
        payload: colorMode,
      });
    },
    [dispatch],
  );

  const translateCustomizationsToCss = useCallback((customizations: HubCustomizations) => {
    let customProperties: Record<string, string> = {};
    const darkMode = customizations?.dark_mode || 'disabled';
    const isDarkMode = darkMode === 'enabled' || (darkMode === 'auto' && detectColorMode() === 'dark');
    const dynamicPrimaryColor = isDarkMode
      ? customizations.primary_color_dark_mode || DEFAULT_PRIMARY_DARK_COLOR
      : customizations.primary_color;
    const containerBorderRadius = customizations?.container_border_radius
      ? Math.min(customizations?.container_border_radius, 30)
      : undefined;

    if (dynamicPrimaryColor) {
      const primaryColorTextComplement = determineTextColorForBgColor(dynamicPrimaryColor, isDarkMode);
      customProperties = {
        ...customProperties,
        '--rph-primary-color': dynamicPrimaryColor,
        '--rph-primary-color-light': customizations.primary_color || DEFAULT_PRIMARY_COLOR,
        '--rph-primary-color-dark': customizations.primary_color_dark_mode || DEFAULT_PRIMARY_DARK_COLOR,
        '--rph-primary-color-text-complement': primaryColorTextComplement,
        '--rph-primary-color-lighter': adjustColor(dynamicPrimaryColor, 40),
        '--rph-primary-color-darker': adjustColor(dynamicPrimaryColor, -30),
        '--rph-primary-color-extra-darker': adjustColor(dynamicPrimaryColor, -50),
        '--rph-link-color': 'var(--rph-primary-color)',
      };
    }

    if (customizations.rounded_corners === false) {
      customProperties = {
        ...customProperties,
        '--rph-nav-radius': '1px',
        '--rph-button-radius': '1px',
        '--rph-container-radius': '1px',
        '--rph-input-radius': '1px',
        '--rph-input-select-radius': '1px',
        '--rph-account-notice-radius': '1px',
      };
    } else if (containerBorderRadius) {
      customProperties = {
        '--rph-nav-radius': `${containerBorderRadius}px`,
        '--rph-button-radius': `${containerBorderRadius}px`,
        '--rph-container-radius': `${containerBorderRadius}px`,
        '--rph-input-radius': `${containerBorderRadius}px`,
        '--rph-input-select-radius': `${containerBorderRadius}px`,
        '--rph-account-notice-radius': `${containerBorderRadius}px`,
        ...customProperties,
      };
    }

    if (customizations.offset_x) {
      customProperties['--rph-nav-offset-x'] = `${customizations.offset_x}px`;
    }

    if (customizations.offset_y) {
      customProperties['--rph-nav-offset-y'] = `${customizations.offset_y}px`;
    }

    if (customizations.property_overrides) {
      // Sanitize db custom properties
      const propertiesFromDb = Object.entries(customizations.property_overrides).reduce(
        (acc: Record<string, string>, [key, value]) => {
          value = value.trim();
          // Remove trailing semi-colon if present
          if (value.endsWith(';')) {
            value = value.slice(0, -1);
          }
          acc[key] = value;
          return acc;
        },
        {},
      );
      customProperties = {
        ...customProperties,
        ...propertiesFromDb,
      };
    }

    if (darkMode === 'enabled') {
      document.getElementById('rownd-privacy-hub')?.classList.add('rph-colorscheme-dark');
    } else if (darkMode === 'auto') {
      document.getElementById('rownd-privacy-hub')?.classList.add('rph-colorscheme-auto');
    }

    const bodyMobile: HTMLElement | null = document?.querySelector('.rownd-mobile-app-presentation');
    const bodyMobileFontSize = bodyMobile?.dataset.rowndFontSize;

    if (bodyMobileFontSize) {
      customProperties['--rph-base-font-size'] = bodyMobileFontSize;
    }

    // Turn customProperties into a CSS string
    const css = `:root {\n\t${Object.entries(customProperties)
      .map(([key, value]) => `${key}:${value};`)
      .join('\n\t')}\n}`;

    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = css;
    document.head.appendChild(style);
  }, []);

  const preloadAssets = useCallback((state: GlobalState) => {
    const appIcon = Selectors.appIcon(state);
    if (appIcon) {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.href = appIcon;
      link.as = 'image';
      document.head.appendChild(link);
    }
  }, []);

  useEffect(() => {
    if (hasLoaded) {
      return;
    }
    if (state.app.config?.customizations || state.app.config?.hub?.customizations) {
      translateCustomizationsToCss({
        ...state.app.config?.customizations,
        ...state.app.config?.hub?.customizations,
      });
      preloadAssets(state);
      setHasLoaded(true);
    }
  }, [
    hasLoaded,
    state.app.config?.customizations,
    state.app.config?.hub?.customizations,
    translateCustomizationsToCss,
    preloadAssets,
    state,
  ]);

  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)')?.addEventListener('change', handleColorModeChange);

    return () => {
      window?.matchMedia('(prefers-color-scheme: dark)')?.removeEventListener('change', handleColorModeChange);
    };
  }, [handleColorModeChange]);

  return null;
}
