import postRobot from 'post-robot';
import isDeepEqual from 'lodash-es/isEqual';
import { IConfig } from '..';
import { events, EventType } from '../events';
import { logger } from '../utils/log';
import { localStore } from './local';
import { GlobalState } from '../hooks/use-global-context';
import _set from 'lodash-es/set';
import { HubStorage } from './types';

enum IFrameSendStorageInitStatus {
  Initialized = 'IFRAME_STORAGE_INITIALIZED',
  NotInitialized = 'IFRAME_STORAGE_NOT_INITIALIZED',
}

const iFrameSendStorageInit = async ({
  iframe,
  securityRequirements,
}: {
  iframe: HTMLIFrameElement;
  securityRequirements: { domain: string };
}): Promise<IFrameSendStorageInitStatus> => {
  return new Promise((resolve) => {
    iframe.onload = () => {
      postRobot
        .send(iframe.contentWindow!, 'storage.init', securityRequirements, {
          timeout: 1000,
        })
        .then(() => {
          resolve(IFrameSendStorageInitStatus.Initialized);
        })
        .catch((err) => {
          resolve(IFrameSendStorageInitStatus.NotInitialized);
          logger.error(err);
        });
    };
  });
};

export async function clientStore(iframe: HTMLIFrameElement, config: IConfig, initCb?: () => void): Promise<HubStorage | null> {
  const store = localStore();

  const securityRequirements = {
    // window: iframe.contentWindow!,
    domain: new URL(config.rootOrigin!).origin,
  };

  postRobot.on('storage.sync', securityRequirements, (event) => {
    logger.debug('storage.sync', event);
    Object.entries(event.data).forEach(([key, value]) => {
      if (key === 'rph_state') {
        const originalStateString = store.getItem('state');
        const newStateString = value as string;
        if (!originalStateString || !newStateString) return;

        const originalState: GlobalState = JSON.parse(originalStateString);
        const newState: GlobalState = JSON.parse(newStateString);

        // Overwrite newState appConfig with original, since we might be using a different app variant (i.e., sub-brand)
        _set(newState, 'app.config', originalState?.app?.config || {});

        if (isDeepEqual(originalState?.app?.config, newState?.app?.config)) {
          return;
        }

        store.setItem(key, JSON.stringify(newState));

        return;
      }

      store.setItem(key, value);
    });
  });

  postRobot.on('storage.init', securityRequirements, (event) => {
    logger.debug('storage.init', event);
    events.dispatch(EventType.STORAGE_READY, {});
    if (initCb) {
      initCb();
    }
  });

  postRobot.on('storage.rootSync', securityRequirements, (event) => {
    events.dispatch(EventType.ROOT_SYNC, event.data);
  });

  const iFrameSendStorageInitStatus = await iFrameSendStorageInit({ iframe, securityRequirements });
  if (iFrameSendStorageInitStatus === IFrameSendStorageInitStatus.NotInitialized) {
    return null;
  }

  async function setItem(key: string, value: any) {
    logger.debug('client.setItem', { key, value });
    store.setItem(key, value);
    return postRobot.send(iframe.contentWindow!, 'storage.setItem', { key, value, ts: Date.now() }).catch(logger.error);
  }

  function getItem(key: string) {
    logger.debug('client.getItem', { key });
    return store.getItem(key);
  }

  async function removeItem(key: string) {
    logger.debug('client.removeItem', { key });
    store.removeItem(key);
    return postRobot.send(iframe.contentWindow!, 'storage.removeItem', { key }).catch(logger.error);
  }

  return {
    setItem,
    getItem,
    removeItem,
  };
}
